import React from "react";
import logo from "../assets/images/logo.png";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer>
      <img src={logo} alt="logo" />
      <a
        href="https://apps.shopify.com/pendora-digital-download"
        target="_blank"
      >
        Powered by <span>Pendora</span>
      </a>
    </footer>
  );
};

export default Footer;
